import React from "react";
import {Link} from "react-router-dom";

export default function Overview({ user }) {
    return (
        <React.Fragment>
            <div className="container">
                <h1 className="text-center text-5xl">Hello, { user?.Name || localStorage.getItem('username') }!</h1>
                <p className="text-center text-2xl">Welcome to Doug Media.</p>
                <div className="grid grid-cols-2 gap-8 mt-16">
                    <Link to="/media/request" className="button text-center text-2xl">
                        REQUEST
                    </Link>
                    <Link to="/media/downloads" className="button text-center text-2xl">
                        MANAGE
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
}