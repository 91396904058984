export default function useAPI() {
    return (path, method = "GET", data = {}) =>
        new Promise((resolve, reject) => {
            if (data && localStorage.getItem('username')) {
                data.username = localStorage.getItem('username');
            }
            fetch(`https://api.dougtheserver.com/${path}`, {
                method,
                ...(["POST", "DELETE"].includes(method) ? {body: JSON.stringify(data)} : {}),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${localStorage.getItem('user_token')}`
                }
            })
                .then(resp => resp.json())
                .then(resolve)
                .catch(reject)
        });
}
