import React, {useState} from "react";
import useAPI from "../hooks/api";
import {useNavigate} from "react-router-dom";

export default function Login({ setUser }) {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const api = useAPI();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <form method="POST" onSubmit={e => {
                e.preventDefault();

                api('auth/login', 'POST', { username, password })
                    .then(resp => {
                        if(!resp.success) return alert("uh oh, wrong password, stinky");

                        localStorage.clear();
                        localStorage.setItem('user_token', resp.token);
                        localStorage.setItem('username', resp.user.Name);

                        setUser(resp.user);
                        return navigate('/media/overview');
                    });
            }} className="container flex flex-col gap-4 text-lg">
                <div className="flex flex-col gap-1">
                    <p className="">Username</p>
                    <input onChange={e => setUsername(e.target.value)} value={username} type="text" placeholder="ThatBigD" />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="">Password</p>
                    <input onChange={e => setPassword(e.target.value)} value={password} type="password" placeholder="********************" />
                </div>
                <button className="button button-blue font-semibold text-center text-xl">
                    Login
                </button>
            </form>
        </React.Fragment>
    )
}
