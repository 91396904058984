import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import useAPI from "../hooks/api";
import {toast} from "react-toastify";

function SelectTorrent({ imdbID }) {
    const [torrents, setTorrents] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("movies");

    const api = useAPI();

    const [selectedTorrent, setSelectedTorrent] = useState("");
    const [movieInformation, setMovieInformation] = useState({});

    useEffect(() => {
        api(`media/search?imdb=${ imdbID }`)
            .then(resp => {
                if(!resp.success) return toast.error(resp.error);

                setMovieInformation(resp.omdb);
                setSelectedTorrent(resp.torrents.length > 0 ? resp.torrents[0].magnet : "");
                return setTorrents(resp.torrents);
            });
    }, [imdbID]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-4">
                <div className="w-48">
                    <img src={ movieInformation.Poster } alt={ movieInformation.Title } />
                </div>
                <div className="flex flex-col gap-4 flex-grow">
                    <p className="text-2xl">{ movieInformation.Title }</p>
                    <div className="flex flex-col gap-1">
                        <span>Genre: { movieInformation.Genre }</span>
                        <span>Year: { movieInformation.Year }</span>
                        <span>Runtime: { movieInformation.Runtime }</span>
                        <span>Rated: { movieInformation.Rated }</span>
                    </div>
                </div>
            </div>
            <p>{ movieInformation.Plot }</p>
            <div className="flex flex-col gap-1">
                <p className="">Download Mirror</p>
                <select value={selectedTorrent} onChange={e => setSelectedTorrent(e.target.value)}>
                    { torrents.map(({ name, seeds, peers, magnet }, idx) => (
                        <option key={ idx } value={ magnet }>{ name } ({ seeds } seeds, { peers } peers)</option>
                    )) }
                </select>
            </div>
            <div className="flex flex-col gap-1">
                <p className="">Category</p>
                <select value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)}>
                    <option value="Movies">Movie</option>
                    <option value="Shows">TV Show</option>
                </select>
            </div>
            <div className="button text-lg" onClick={() =>
                api(`media/torrent`, "POST", {
                    imdb: imdbID,
                    magnet: selectedTorrent,
                    type: selectedCategory
                })
                    .then(resp => {
                        if(!resp.success) return toast.error(resp.error);

                        return toast.success("Successfully requested media");
                    }
                )
            }>
                Request
            </div>
        </div>
    )
}

function Name() {
    const [searchResults, setSearchResults] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const [showTorrentResults, setShowTorrentResults] = useState(false);
    const [selectedImdbId, setSelectedImdbId] = useState("");

    const api = useAPI();

    if(showTorrentResults) return <SelectTorrent imdbID={selectedImdbId} />

    return (
        <div className="flex flex-col gap-4">
            { !searchResults &&
                <>
                    <div className="flex flex-col gap-1">
                        <p className="">Media Name</p>
                        <input onChange={e => {
                            if(e.target.value.includes('imdb.com/title/')) e.target.value = e.target.value.split('imdb.com/title/')[1].split('/')[0];
                            setSearchQuery(e.target.value);
                        }} value={searchQuery} type="text" placeholder="TV Show / Movie / etc...." />
                    </div>
                    <div onClick={() => {
                        api(`media/search?query=${ searchQuery }`)
                            .then(resp => {
                                if(!resp.success) return toast.error(resp.error);

                                setSelectedImdbId(resp.results[0].imdb);
                                return setSearchResults(resp.results);
                            });
                    }} className="button text-lg">
                        Search
                    </div>
                </>
            }
            { searchResults &&
                <>
                    <div className="flex flex-col gap-1">
                        <p className="">Media Name</p>
                        <select value={selectedImdbId} onChange={e => setSelectedImdbId(e.target.value)}>
                            { searchResults.map(({ title, year, type, imdb }, idx) => <option value={imdb} key={idx}>{ title } [{ year }] ({ type })</option>) }
                        </select>
                    </div>
                    <div onClick={() => {
                        setShowTorrentResults(true);
                    }} className="button text-lg">
                        Select
                    </div>
                </>
            }
        </div>
    );
}

function IMDB() {
    const [imdbId, setImdbId] = useState("");
    const [showTorrentResults, setShowTorrentResults] = useState(false);

    if(showTorrentResults) return <SelectTorrent imdbID={imdbId} />

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
                <p className="">IMDB ID / URL</p>
                <input onChange={e => setImdbId(e.target.value)} value={imdbId} type="text" placeholder="tt1234567" />
            </div>
            <div className="button text-lg" onClick={() =>
                setShowTorrentResults(true)
            }>
                Lookup
            </div>
        </div>
    );
}

function Magnet() {
    const [magnetURL, setMagnetURL] = useState("");
    const [category, setCategory] = useState("movies");

    const api = useAPI();

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
                <p>Magnet Link</p>
                <textarea value={magnetURL} onChange={e => setMagnetURL(e.target.value)} placeholder="magnet:.....magnet:..." />
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                    <select value={category} onChange={e => setCategory(e.target.value)} className="cursor-pointer">
                        <option value="Shows">TV Show</option>
                        <option value="Movies">Movie</option>
                    </select>
                </div>
                <div onClick={() => {
                    setMagnetURL("");

                    api('media/torrent', "POST", {
                        magnet: magnetURL,
                        type: category
                    })
                        .then(resp => {
                            if(!resp.success) return toast.error(resp.error);

                            return toast.success("Successfully added magnet to be downloaded.");
                        });
                }} className="button text-lg">
                    Download
                </div>
            </div>
        </div>
    );
}

export default function Request() {
    const [selectedMethod, setSelectedMethod] = useState(false);

    const methods = {
        'name': { label: "Search By Name", component: Name },
        'imdb': { label: "IMDB ID / URL", component: IMDB },
        'magnet': { label: "Magnet / Torrent URL", component: Magnet }
    }

    const Component = methods[selectedMethod] ? methods[selectedMethod].component : null;

    return (
        <React.Fragment>
            <div className="container relative">
                { selectedMethod === false && <Link to="/media/overview" className="absolute top-8 right-8 text-2xl button-image pl-2 pr-1 -py-1">X</Link> }
                { selectedMethod !== false && <span onClick={() => setSelectedMethod(false)} className="absolute top-8 right-8 text-2xl button-image cursor-pointer pl-2 pr-1 -py-1">X</span> }
                <h1 className="text-4xl text-center">Request Media</h1>
                { selectedMethod === false &&
                    <>
                        <p className="text-xl text-center">Please select one of the following methods of requesting media.</p>
                        <div className="flex flex-col gap-4 mt-8 text-xl text-center">
                            { Object.keys(methods).map(key => <div onClick={() => setSelectedMethod(key)} className="button">{ methods[key].label }</div>) }
                        </div>
                    </>
                }
                { selectedMethod !== false &&
                    <>
                        <p className="text-xl text-center">Selected Method: { methods[selectedMethod].label }</p>
                        <div className="mt-8">
                            <Component />
                        </div>
                    </>
                }
            </div>
        </React.Fragment>
    )
}