import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Login from "./pages/login";
import useAPI from "./hooks/api";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Downloads from "./pages/downloads";
import Overview from "./pages/overview";
import Request from "./pages/request";

function Router() {
    const [user, setUser] = useState({});
    const api = useAPI();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        api('auth/verify')
            .then(resp => {
                if(!resp.success) {
                    localStorage.removeItem('user_token');
                    localStorage.removeItem('username');
                    return navigate('/auth/login');
                }

                if(location.pathname.indexOf("/auth") === 0) navigate('/media/overview');

                return setUser(resp.user);
            }).catch(console.error);
    }, []);

    return (
        <>
            <Routes>
                <Route path="auth">
                    <Route path="login" element={<Login { ...{ setUser } } />} />
                </Route>
                <Route path="media">
                    <Route path="overview" element={<Overview { ...{ user } } />} />
                    <Route path="downloads" element={<Downloads { ...{ user } } />} />
                    <Route path="request" element={<Request { ...{ user } } />} />
                </Route>
                <Route path="*" element={<Login { ...{ setUser } } />} />
            </Routes>
        </>
    )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <div className="h-screen w-screen background">
          <div className="max-w-md mx-auto pt-48 flex flex-col gap-4">
              <div className="flex items-center justify-center mb-24">
                  <img alt="" src="/img/dougmedia.png" className="absolute w-[35rem]" />
              </div>
              <BrowserRouter>
                  <Router />
              </BrowserRouter>
              <ToastContainer theme="dark" />
          </div>
      </div>
  </React.StrictMode>
);
