import React, {useEffect, useState} from "react";
import useAPI from "../hooks/api";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

export default function Downloads() {
    let [downloads, setDownloads] = useState([]);
    const api = useAPI();

    useEffect(() => {
        api('media/torrent')
            .then(async resp => {
                if(!resp.success) return toast.error(resp.error);

                const token = await new Promise(resolve => {
                    api('media/websocket_token', "POST")
                        .then(resp => resolve(resp.success ? resp.token : false))
                        .catch(console.error);
                });

                const socket = new WebSocket('wss://api.dougtheserver.com/media/websocket');

                socket.addEventListener('open', event => {
                    socket.send(JSON.stringify({ type: 'authenticate', token: token }));
                });

                socket.addEventListener('message', ({ data: raw }) => {
                    const data = JSON.parse(raw.toString());

                    if(data.type === "update_torrent_data") {
                        let updated = false;

                        for(const [key, value] of Object.entries(downloads)) {
                            if (value.info_hash === data.torrent.info_hash) {
                                downloads[key] = data.torrent;
                                updated = true;
                            }
                        }

                        if(!updated) {
                            downloads.push(data.torrent);
                        }

                        setDownloads([
                            ...downloads
                        ]);
                    } else if(data.type === "remove_torrent") {
                        downloads = downloads.filter(x => x.info_hash !== data.torrent);
                        setDownloads([
                            ...downloads
                        ]);
                    }
                })

                downloads = resp.torrents;
                return setDownloads(resp.torrents);
            });
    }, []);

    return (
        <React.Fragment>
            <div className="container text-center text-lg relative">
                <Link to="/media/overview" className="absolute top-8 right-8 text-2xl button-image pl-2 pr-1 -py-1">X</Link>
                <h1 className="text-4xl text-center">Manage Media</h1>
                <div className="grid grid-cols-6 px- py-0.5 gap-10 mt-10">
                    <p>Name</p>
                    <p>State</p>
                    <p>Eta</p>
                    <p>Speed</p>
                    <p>Progress</p>
                    <p>Peers</p>
                </div>
                <div className="max-h-100 overflow-y-auto flex flex-col gap-2 px-0.5">
                    { downloads.map(({ info_hash, name, download_speed, progress, num_peers, state, eta }, idx) => {
                        return (
                            <div key={idx} className="px-1 py-1 border-2 border-black/80 grid grid-cols-6 gap-1 relative items-center">
                                <p aria-label={name} className="truncate label cursor-pointer z-0">{ name }</p>
                                <p className="truncate">{ state }</p>
                                <p className="truncate">{ eta }</p>
                                <p className="truncate">{ download_speed }mbps</p>
                                <div className="bg-red-700 relative flex items-center justify-center h-full">
                                    <div className="h-full w-full">
                                        <div className="bg-green-700 h-full" style={{ width: `${ progress }%` }} />
                                    </div>
                                    <span className="absolute">{ progress }%</span>
                                </div>
                                <p className="truncate">{ num_peers }</p>
                                <span onClick={() => api('media/torrent', "DELETE", { info_hash, name })} className="text-red-500 absolute right-1 cursor-pointer">X</span>
                            </div>
                        )
                    }) }
                    { downloads.length === 0 && <p className="text-center mt-2">no current downloads</p> }
                </div>
            </div>
        </React.Fragment>
    )
}
